import React from 'react';
export const PartTwo = (props) => {
    return (
      <header id='partTwo'>
        <div className='introPartTwo'>
          <div className='overlay'>
            <div className='container'>
            </div>
          </div>
        </div>
      </header>
    )
  }
  