import React from 'react';
import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const PartFive = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <header id='partFive'>
      <div className='introPartFive'>
        {/* <div className='overlay'>
          <div className='container'>
            <div className='row'> */}


        <form name='sentMessage' validate onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-md-5556'>
              <div className='form-group'>
                <input
                  type='text'
                  id='name'
                  name='name'
                  className='form-control'
                  placeholder='First Name'
                  required
                  onChange={handleChange}
                />
                <p className='help-block text-danger'></p>
              </div>
              <div className='form-group'>
                <input
                  type='text'
                  id='name'
                  name='name'
                  className='form-control'
                  placeholder='Last Name'
                  required
                  onChange={handleChange}
                />
                <p className='help-block text-danger'></p>
              </div>

            </div>
            <div className='col-md-6'>
              {/* <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div> */}
            </div>
          </div>
          <div className='form-group'>
            <textarea
              name='message'
              id='message'
              className='form-control'
              rows='1'
              placeholder='Message'
              required
              onChange={handleChange}
            ></textarea>
            <p className='help-block text-danger'></p>
          </div>
          <div id='success'></div>
          <button type='submit' className='btn btn-custom btn-lg'>
            GET STARTED NOW
                </button>
        </form>

        {/* <div className='col-md-8 col-md-offset-2 intro-text'>
                  <h1>
                    {props.data ? props.data.title : 'Loading'}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                  <a
                    href='#features'
                    className='btn btn-custom btn-lg page-scroll'
                  >
                    Read More
                  </a>{' '}
                </div> */}
        {/* </div>
          </div>
        </div> */}
      </div>
    </header>
  )
}
