import React, { useState, useEffect } from 'react'
import { Navigation } from './components/navigation'
import { Header } from './components/header'
import { PartTwo } from './components/partTwo'
import { PartThree } from './components/partThree'
import { PartFour } from './components/partFour'
import { PartFive } from './components/partFive'
import { PartSix } from './components/partSix'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <PartTwo data={landingPageData.PartTwo} />
      <PartThree data={landingPageData.PartThree} />
      <PartFour data={landingPageData.PartFour} />
      <PartFive data={landingPageData.PartFive} />
      <PartSix data={landingPageData.PartSix} />
    </div>
  )
}

export default App
