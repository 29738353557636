import React from 'react';

export const Navigation = (props) => {
    return (
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <a className='navbar-brand page-scroll' href='#page-top'>
              {/* Primebond */}
            </a>{' '}
          </div>
  
          <div
            className='collapse navbar-collapse'
            id='bs-example-navbar-collapse-1'
          >
            <ul className='nav navbar-nav navbar-right'>
              {/* <li>
                <a href='#features' className='page-scroll'>
                  Features
                </a>
              </li> */}
              <li>
                <a href='#partTwo' className='page-scroll'>
                  About Us
                </a>
              </li>
              <li>
                <a href='#partThree' className='page-scroll'>
                  Quality Assurance
                </a>
              </li>
              <li>
                <a href='#partFour' className='page-scroll'>
                  Products
                </a>
              </li>
              {/* <li>
                <a href='#testimonials' className='page-scroll'>
                  Testimonials
                </a>
              </li> */}
              <li>
                <a href='#partFive' className='page-scroll'>
                  Raw Material
                </a>
              </li>
              <li>
                <a href='#partSix' className='page-scroll'>
                  Request a Quote
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }